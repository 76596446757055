import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import logoAvr from '../../../src/avr_logo.png'
import logoFirenze from '../../../src/stemmaFirenze.png'
import logoARegoladArte from '../../../src/logo_regola_darte.svg'
import '../styles/footer.css'


export default function Footer(){

    const history = useHistory();

    const goToLogin = () => {
        localStorage.removeItem('token')
        history.push('/')
    }

    return(
        <div className='footerDiv'>
            <div className='leftLogout'>
                <p className='logoutText' onClick={(e) => goToLogin(e)}> 
                    <FontAwesomeIcon
                        className="logoutIcon" 
                        icon={faDoorOpen}
                        size="1x"
                />Logout</p>
                <Link to='../pages/policy' className='policyFooter'>Policy</Link>
            </div>
            <div className='rightLogosDiv'>
                <div className='logoFirenze'>
                    <img src={logoFirenze} alt='logoFirenze' width='50' height='50'/>
                </div>
                <div className='logoaRegoladArte'>
                    <img src={logoARegoladArte} alt='logoaregoladarte' width='50' height='50'/>
                </div>
                <div className='logoAvr'>
                    <img src={logoAvr} alt='logoAvr' width='50' height='50'/>
                </div>
            </div>
        </div>
    );
};