import React, { useEffect, useState } from 'react';

import { Modal, Button, TextInput, Textarea, Checkbox } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '../styles/dati.css'
import emailIcon from '../../email.png' 

export default function Dati( props ){

    /** HOOKS */
    const [showError, setShowError] = useState('none');
    const [showErrorPermission, setShowErrorPermission] = useState('none');

    const [progressValue, setProgressValue] = useState('');
    const [statoSegnalazione, setStatoSegnalazione] = useState('');
    const [newDataInoltroSplitted, setNewDataInoltroSplitted] = useState('');
    const [newDataChiusuraSplitted, setNewDataChiusuraSplitted] = useState('');
    const [statoDettaglio, setStatoDettaglio] = useState('');
    const [opened, setOpened] = useState(false);
    const [openOkModal, setOpenOkModal] = useState(false)
    const [openBadModal, setOpenBadModal] = useState(false)
    const [richiesta, setRichiesta] = useState('');
    const [email, setEmail] = useState('')
    const [checked, setChecked] = useState(false)
    /* const [isDisabled, setIsDisabled] = useState(true) */


    /* let segnalazione1 = '155555' */

    /** PROPS */
    const socket = props.socket;
    const segnalazione = props.segnalazione;
    const setSegnalazione = props.setSegnalazione;
    const showSpinner = props.showSpinner;
    const setShowSpinner = props.setShowSpinner;
    const showElem = props.showElem;
    const setShowElem = props.setShowElem;
    const setShowSearch = props.setShowSearch;
    const setShowDati = props.setShowDati;
    const elem = props.elem;
    const setElem = props.setElem;
    const setShowMap = props.setShowMap;
    const setShowRight = props.setShowRight;

    /** VARIABILI UTILITY */
    let parsedData;
    /* var dataInoltroSplitted;
    var dataChiusuraSplitted; */

    function dataIsCETorCEST(d){
        if(!d){
            return ''
        }
        let existsCEST = d.split('CEST').length > 1
        let existsCET

        if(existsCEST === false){
            existsCET = d.split('CET').length > 1
            return new Date(d.split('CET')[0]).toLocaleDateString()
        }

        return new Date(d.split('CEST')[0]).toLocaleDateString()
    }

    //useEffect che ascolta sul canale socket 'dataShow' per i cambiamenti
    useEffect(() => {

        if(socket){
            socket.on('dataShow', function(data){
                if(data){
                    /* console.log(data) */
                    parsedData = data
                    console.log('parsedData', parsedData)
                    if(parsedData == 'errore'){
                        setShowSpinner('none');
                        setShowError('flex');
                        setShowRight('flex');
                        setShowMap('none');
                        return
                    }

                    if(parsedData.error == 'data not available'){
                        setShowSpinner('none');
                        setShowErrorPermission('flex');
                        setShowRight('flex');
                        setShowMap('none');
                        return
                    }

                    setShowSpinner('none')
                    setShowElem('flex')
                    setElem(parsedData)
                    /* console.log('elem', elem) */


                    const segnalazioneInfo = parsedData.bodyfindSegnalazione[0]

                    const dataInoltro = segnalazioneInfo.data_inoltro != null /* || segnalazioneInfo.data_inoltro.length > 0  */
                        ? dataIsCETorCEST(segnalazioneInfo.data_inoltro)
                        : ''

                    const dataChiusura = segnalazioneInfo.data_chiusura != null /* || segnalazioneInfo.data_chiusura.length > 0  */
                        ? dataIsCETorCEST(segnalazioneInfo.data_chiusura)
                        : ''

                    setNewDataInoltroSplitted(dataInoltro)
                    setNewDataChiusuraSplitted(dataChiusura)
                    console.log(dataInoltro, dataChiusura)


                    /** DOM ELEMENTS */
                    var luceTop = document.getElementById('luce1');
                    var luceMid = document.getElementById('luce2');
                    var luceBottom = document.getElementById('luce3');
                    var progressBar = document.getElementById('progressBar');

                    //FARE CONTROLLO SULLA RESPONSE BODYFINDSEGNALAZIONE PER I CONTROLLI NULL, UNDEFINED ECC
                    switch(parsedData.bodyfindSegnalazione[0].stato_getico){
                        //da importare
                        case 'DV':
                            //non c'è bisogno di cambiare colore alla progress bar
                            //settare la luce rossa top
                            luceTop.style.background = '#c30c26';
                            luceMid.style.background = '#adadad';
                            luceBottom.style.background = '#adadad';
                            setProgressValue('0');
                            setStatoSegnalazione('Da valutare');
                            setStatoDettaglio('Da valutare')
                        break;

                        //non di competenza
                        case 'NC':
                            //devo cambiare il colore a verde
                            progressBar.className = 'progressBarFullGreen';
                            //devo settare la luce bottom rossa
                            luceTop.style.background = '#adadad';
                            luceMid.style.background = '#adadad';
                            luceBottom.style.background = '#c30c26';
                            setProgressValue('100');
                            setStatoSegnalazione('Non di competenza del Global Service');
                            setStatoDettaglio('Non di competenza del Global Service')
                        break;

                        //importato
                        case 'IM':
                            if(parsedData.bodyfindSegnalazione[0].stato_seg == 'A'){
                            //devo cambiare il colore a verde
                            progressBar.className = 'progressBarHalfGreen';
                            //devo settare luce mid gialla
                            luceTop.style.background = '#adadad';
                            luceMid.style.background = '#ffc800';
                            luceBottom.style.background = '#adadad';
                            setProgressValue('50');
                            setStatoSegnalazione('Importato su Ma.G.I.Stra / In lavorazione');
                            setStatoDettaglio('Aperto');
                        } else {
                            //eseguito
                            //devo cambiare il colore a verde
                            progressBar.className = 'progressBarFullGreen';
                            //devo settare la luce bottom verde
                            luceTop.style.background = '#adadad';
                            luceMid.style.background = '#adadad';
                            luceBottom.style.background = '#369e28';
                            setProgressValue('100');
                            setStatoSegnalazione('Eseguito');
                            setStatoDettaglio('Eseguito');
                        }
                        break;

                        //inoltrato
                        case 'IN':
                            //devo cambiare il colore a blu
                            progressBar.className = 'progressBarBlue';
                            //devo settare luce bottom blu
                            luceTop.style.background = '#adadad';
                            luceMid.style.background = '#adadad';
                            luceBottom.style.background = '#3e6ac7';
                            setProgressValue('100');
                            setStatoSegnalazione('Inoltrato ad ufficio esterno di competenza');
                            setStatoDettaglio('Inoltrato')
                        break;
                    }
                }
            })
        }
        return () => {
            setElem({});
            setProgressValue('');
            setStatoSegnalazione('');
            setShowError('none');
            setShowErrorPermission('none');
        };
    }, [showSpinner])

    /* console.log('elem', elem) */

    /** funzione per tornare alla selezione del getico */
    const goBack = (e) => {
        e.preventDefault();
        if(showError == 'flex'){
            setShowError('none')
        }
        if(showErrorPermission == 'flex'){
            setShowErrorPermission('none')
        }
        setShowElem('none');
        setShowSearch('flex');
        setShowDati('none');
        setSegnalazione(undefined);
        setProgressValue('');
        setElem({});
        setShowMap('none');
        setShowRight('flex');
        setStatoSegnalazione('')
    }

    //funzione per inviare la mail
    const sendEmail = (e, idGetico, messaggio, emailRichiedente) => {
        e.preventDefault()
        //devo inviare al backend i dati
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("idGetico", idGetico);
        urlencoded.append("messaggio", messaggio);
        urlencoded.append("email", emailRichiedente)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
    
        fetch("https://presidentidiquartiere.firenzearegoladarte.it/api/sendEmail", requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.message === 'Tutto ok'){
                    setOpened(false)
                    setOpenOkModal(true)
                }else {
                    setOpened(false)
                    setOpenBadModal(true)
                }
            })
            .catch(error => console.log('error', error));
        
        setRichiesta('')
        setEmail('')
        setChecked(false)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setRichiesta('')
        setChecked(false)
        setEmail('')
        setOpened(false)
    }

    return (
        <>
            <div className='dataResponse' style={{display: showElem}}>
                <div className='graphicResponse'>
                    <div className='semaforoWrapper'>
                        <div className='semaforo'>
                            <div className='semaforoTopDecoration1'></div>
                            <div className='semaforoTopDecoration2'></div>
                            <div className='innerLuce1'></div>
                            <div className='luce1' id='luce1'></div>
                            <div className='innerLuce2'></div>
                            <div className='luce2' id='luce2'></div>
                            <div className='innerLuce3'></div>
                            <div className='luce3' id='luce3'></div>
                        </div>
                    </div>
                    <div className='textInfos'>
                        <p className='statoDesktop'>{statoSegnalazione}</p>
                        <p className='statoMobile'>{statoSegnalazione}</p>
                        <div className='progressBarWrapper'>
                            <progress className='progressBar' id='progressBar' value={progressValue} max="100"></progress>
                        </div>
                    </div>
                </div>
                <div className='dataWrapper' style={{display: showElem}}>
                    <p className='dataID data'>ID Ma.G.I.Stra: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].id_segnalazione : ''}</span></p>
                    <p className='dataIndirizzo data'>Indirizzo: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].indirizzo : ''}</span></p>
                    <p className='dataCivico data'>Civico: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].civico : ''}</span></p>
                    <p className='dataQuartiere data'>Quartiere: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].quartiere : ''}</span></p>
                    <p className='dataDataInoltro data'>Data inoltro: <span>{Object.keys(elem).length > 0 ? newDataInoltroSplitted : ''}</span></p>
                    <p className='dataDataChiusura data'>Data chiusura: <span>{Object.keys(elem).length > 0 ? newDataChiusuraSplitted : ''}</span></p>
                    <p className='dataID data'>Dettaglio localizzazione: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].dettaglio_localizzazione : ''}</span></p>
                    <p className='dataIndirizzo data'>Categoria anomalia: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].categoria_anomalia : ''}</span></p>
                    <p className='dataCivico data'>Sottocategoria anomalia: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].sottocategoria_anomalia : ''}</span></p>
                    <p className='dataQuartiere data'>Descrizione originale: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].descrizione_originale : ''}</span></p>
                    <p className='dataDataInoltro data'>Stato dettaglio: <span>{Object.keys(elem).length > 0 ? statoDettaglio : ''}</span></p>
                    <p className='dataDataChiusura data'>Sottocategoria lavoro: <span>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].sottocategoria_lavoro : ''}</span></p>
                </div>
                <div className="btnContainer">
                    <div className='btnGoBackContainer'>
                        <button className='buttonGoBack' onClick={(e) => goBack(e)}>Nuova ricerca</button>
                    </div>
                    <div className="emailButtonContainer">
                        <button className="emailButton" onClick={() => setOpened(true)}><img src={emailIcon} alt="email_icon"/></button>
                    </div>
                    <>
                    <Modal
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title="Inserisca la richiesta"
                        transition="fade"
                        transitionDuration={600}
                        transitionTimingFunction="ease"
                        closeOnClickOutside
                        size={window.innerWidth >= 1440
                        ? '40%': '90%'}
                    >
                        <TextInput style={{marginTop: '1em'}} label="ID Getico" value={segnalazione ? segnalazione : ''} disabled/>
                        <TextInput style={{marginTop: '1em'}} label="Email" placeholder="Inserisca l'email..." value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <Textarea style={{marginTop: '1em'}} label="Richiesta" value={richiesta} minRows={10} onChange={(e) => setRichiesta(e.currentTarget.value)}/>
                        <div className="policyBreveContainer">
                            <div className="privacyPolicyBreve">
                                <h3 className="privacyPolicyBreveTitle">Informativa ai sensi dell’art. 13 del Regolamento (UE) 2016/679 
                                    (Regolamento Generale sulla Protezione dei Dati).
                                </h3>
                                <p className="privacyPolicyBreveText">Ai sensi dell’art. 13 del Regolamento (UE) 2016/679, ed in relazione ai suoi dati personali di cui la nostra società entrerà in possesso, La informiamo che limitatamente al servizio di gestione e riscontro delle segnalazioni, il titolare del trattamento è AVR S.p.A.; il Responsabile della protezione dei dati è l’Avv. William Di Cicco e il trattamento dei Suoi dati personali forniti è finalizzato alla gestione e riscontro delle segnalazioni richieste mediante la compilazione dell’apposito form sull’APP Presidenti di quartiere.
                                Verranno trattati unicamente i dati pertinenti e necessari alla finalità indicata, in modo lecito, corretto, trasparente e adeguatamente sicuro. <b>I Suoi dati potranno essere acquisiti o comunicati al Comune di Firenze</b> e il trattamento potrà avvenire in forma cartacea e digitale con l’ausilio di strumenti informatici e telematici e i dati saranno conservati su banche dati del Titolare. Potrà rivolgersi al Titolare del trattamento per esercitare i Suoi diritti. Con l’invio della segnalazione lei acconsente al trattamento dei dati personali in esso forniti secondo le modalità e nei limiti di cui all’informativa completa presente sul sito internet <a href="https://www.avrgroup.it" target="_blank">www.avrgroup.it/privacy-e-cookie-policy</a>
                                </p>
                            </div>
                        </div>
                        {/* <Checkbox style={{marginTop: '2em'}} label="Privacy - Si acconsente al trattamento dei dati personali (email) per essere ricontattati da AVR S.p.A., gestore del Global Service" checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} /> */}
                        <Checkbox style={{marginTop: '2em'}} label="Ho letto la normativa privacy" checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} />
                        <div className="modalButtonContainer">
                            <Button disabled={checked === false ? true : false} color="red" onClick={(e) => sendEmail(e, segnalazione, richiesta, email)}>Invia</Button>
                            <Button color="red" onClick={(e) => handleCancel(e)}>Annulla</Button>
                        </div>
                    </Modal>
                    <Modal
                        opened={openOkModal}
                        onClose={() => setOpenOkModal(false)}
                        transition="fade"
                        transitionDuration={600}
                        transitionTimingFunction="ease"
                        closeOnClickOutside
                        size="50%"
                        title="Messaggio inviato correttamente"
                    >
                    </Modal>
                    <Modal
                        opened={openBadModal}
                        onClose={() => setOpenBadModal(false)}
                        transition="fade"
                        transitionDuration={600}
                        transitionTimingFunction="ease"
                        closeOnClickOutside
                        size="50%"
                        title="Messaggio inviato correttamente"
                    >
                    </Modal>
                    </>
                </div>
            </div>
            
            <div style={{display: showError}} className="divError">
                <p className='dataNotFound'>Segnalazione non trovata</p>
                <button className='buttonGoBack' onClick={(e) => goBack(e)}>Nuova ricerca</button>
            </div>
            <div style={{display: showErrorPermission}} className="divError">
                <p className='dataNotFound'>Segnalazione non disponibile. Non si possiedono i necessari permessi.</p>
                <button className='buttonGoBack' onClick={(e) => goBack(e)}>Nuova ricerca</button>
            </div>

            <FontAwesomeIcon 
                icon={faSpinner} 
                size='3x'
                style={{display: showSpinner}}
                className='spinnerIcon'
            />
        </>
    );
};