import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import '../styles/stats.css'


export default function Stats( props ){

    /** HOOK */
    const [quartiere, setQuartiere] = useState();
    const [segnalazioniChiuse, setSegnalazioniChiuse] = useState();
    const [nonDiCompetenza, setNondiCompetenza] = useState();
    const [importati, setImportati] = useState();
    const [daValutare, setDaValutare] = useState();
    const [inoltrati, setInoltrati] = useState();
    const [totalValue, setTotalValue] = useState(0);

    /** PROPS */
    const socket = props.socket;

    /** useEffect che prende dal localStorage il token. Commentare se vogliamo accedere senza login */
    useEffect(() => {
        const token = localStorage.getItem('token')
        setQuartiere(token.slice(1,3).toUpperCase())
    }, [])

    //useEffect per la renderizzazione dei dati dei count
    useEffect(() => {
        if(socket){
            socket.on('countAll', function(dataAll){
                if(dataAll){
                    console.log(dataAll)
                    setSegnalazioniChiuse(dataAll[3][0].count) //scommentare in production
                    setTotalValue(dataAll[0][0][0].count)      //scommentare in production
                    /* setSegnalazioniChiuse(dataAll[3].count) //scommentare in local testing
                    setTotalValue(dataAll[0].count) */         //scommentare in local testing

                    for(let i = 0; i < dataAll[2][0].length; i++){        //scommentare in production
                        const state = dataAll[2][0][i].stato_getico;
                        console.log(totalValue, dataAll[2])
                        switch(state){
                            case 'NC':
                                // setNondiCompetenza(Math.ceil((dataAll[2][0][i].count / dataAll[0][0][0].count) * 100))
                                setNondiCompetenza(dataAll[2][0][i].count)
                                break;
                            case 'IM':
                                // setImportati(Math.ceil((dataAll[2][0][i].count / dataAll[0][0][0].count) * 100))
                                setImportati(dataAll[2][0][i].count)
                                break;
                            case 'DV':
                                // setDaValutare(Math.ceil((dataAll[2][0][i].count / dataAll[0][0][0].count) * 100))
                                setDaValutare(dataAll[2][0][i].count)
                                break;
                            case 'IN':
                                // setInoltrati(Math.ceil((dataAll[2][0][i].count / dataAll[0][0][0].count) * 100))
                                setInoltrati(dataAll[2][0][i].count)
                                break;
                        }
                    }

                    //scommentare in local testing                   
                    /* setDaValutare(dataAll[2].daValutare)
                    setImportati(dataAll[2].importati)
                    setInoltrati(dataAll[2].inoltrati)
                    setNondiCompetenza(dataAll[2].nonDiCompetenza) */

                } else {
                    setSegnalazioniChiuse(0);
                    setNondiCompetenza(0);
                    setImportati(0);
                    setDaValutare(0);
                    setInoltrati(0);
                }
            })
        }
        return () => {
            setSegnalazioniChiuse();
            setNondiCompetenza();
            setImportati();
            setDaValutare();
            setInoltrati();
        }
    }, [socket])

    return(
        <div className='chartsContainer'>
            <div className='SegnalazioniGeticoContainer'>
                <div className='legenda'>
                    <div className='legendaElem1'>
                        <p>Non di competenza</p>
                        <div className='color1'></div>
                    </div>
                    <div className='legendaElem2'>
                        <p>Importati</p>
                        <div className='color2'></div>
                    </div>
                    <div className='legendaElem3'>
                        <p>Da valutare</p>
                        <div className='color3'></div>
                    </div>
                    <div className='legendaElem4'>
                        <p>Inoltrati</p>
                        <div className='color4'></div>
                    </div>
                </div>
                <div className='graficoSegnalazioniGetico'>
                    <Pie 
                        data={{
                            labels:['Non di competenza', 'Importati', 'Da valutare', 'Inoltrati'],
                            datasets: [{    //dati da mostrare
                                label: 'Segnalazioni Getico',
                                data: [nonDiCompetenza, importati, daValutare, inoltrati],
                                backgroundColor: ['#FEF266', '#379634', '#FF0000', '#4F77AA'], //colore di sfondo dei vari punti
                                hoverOffset: 1
                            }]
                        }}
                        plugins={[ChartDataLabels]}
                        options = {{
                            layout: {
                                padding: 20
                            },
                            plugins: {
                                legend: {
                                    display: false
                                },
                                datalabels: {
                                    color: '#c30c26',
                                    font: {
                                        size: 17,
                                        weight: 'bold'
                                    },
                                    anchor: 'end',
                                    clamp: true,
                                    align: 'end',
                                    formatter: function(value) {
                                        return Math.floor((value / totalValue) * 100) + '%';
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <div className='segnalazioniChiuse'>
                {
                    quartiere == 'QA' ?
                    <>
                        <h3>Segnalazioni chiuse totali</h3>
                        <p>{segnalazioniChiuse ? segnalazioniChiuse : 0}</p>
                    </>
                    :
                    <>
                        <h3>Segnalazioni chiuse {quartiere}</h3>
                        <p>{segnalazioniChiuse ? segnalazioniChiuse : 0}</p>
                    </>
                }
                
            </div>
        </div>
    );
};