import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom' 

import '../styles/login.css';
import logoAvr from '../../../src/avr_logo.png'
import logoFirenze from '../../../src/comune_firenze_logo.svg'
import david from '../../../src/david3.png'
import logoARegolaDArte from '../../../src/logo_regola_darte.svg'
import {motion} from 'framer-motion/dist/framer-motion'

export default function Login(){

    //manca la parte di error handling

    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState('none');
    const [hideElements, setHideElements] = useState('flex')
    const refUsername = useRef();
    const refPassword = useRef();

    //useEffect che fa la 3d perspective della foto
    useEffect(() => {
        // Init
        var container = document.getElementById("loginWrapper"),
            inner = document.getElementById("fotoDavid");
      
        // Mouse
        var mouse = {
          _x: 0,
          _y: 0,
          x: 0,
          y: 0,
          updatePosition: function(event) {
            var e = event || window.event;
            this.x = e.clientX - this._x;
            this.y = (e.clientY - this._y) * -1;
          },
          setOrigin: function(e) {
            this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
            this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
          },
          show: function() {
            return "(" + this.x + ", " + this.y + ")";
          }
        };
      
        // Track the mouse position relative to the center of the container.
        mouse.setOrigin(container);
      
        //----------------------------------------------------
      
        var counter = 0;
        var refreshRate = 10;
        var isTimeToUpdate = function() {
          return counter++ % refreshRate === 0;
        };
      
        //----------------------------------------------------
      
        var onMouseEnterHandler = function(event) {
          update(event);
        };
      
        var onMouseLeaveHandler = function() {
          inner.style.background = `${david}`;
          inner.style.backgroundRepeat = 'no-repeat';
          inner.style.backgroundPosition = 'center';
        };
      
        var onMouseMoveHandler = function(event) {
          if (isTimeToUpdate()) {
            update(event);
          }
        };
      
        //----------------------------------------------------
      
        var update = function(event) {
          mouse.updatePosition(event);
          /* console.log(mouse.x + " " + mouse.y); */
          updateTransformStyle(
            (mouse.y / inner.offsetHeight / 2).toFixed(2),
            (mouse.x / inner.offsetWidth / 2).toFixed(2)
          );
        };
      
        var updateTransformStyle = function(x, y) {
          var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
          inner.style.transform = style;
          inner.style.webkitTransform = style;
          inner.style.mozTranform = style;
          inner.style.msTransform = style;
          inner.style.oTransform = style;
        };
      
        //--------------------------------------------------------
      
        document.onmousemove = onMouseMoveHandler;
        document.onmouseleave = onMouseLeaveHandler;
        document.onmouseenter = onMouseEnterHandler;
      }, [])

    const goToDashboard = () => {
            /** scommentare se si vuole procedere senza il login auth */
            /* history.push('/dashboard'); */
            /** commentare se si vuole procedere senza il login auth */
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("username", username);
            urlencoded.append("password", password);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            //sostituire se si vuole fare test col login
            // http://localhost:8001/login
            //sostituire in production
            // https://presidentidiquartiere.firenzearegoladarte.it/api/login
            fetch("https://presidentidiquartiere.firenzearegoladarte.it/api/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                /* console.log('result', result) */
                if(result['permission-token']){
                    localStorage.setItem('token', result['permission-token'])
                    history.push('/dashboard');
                } else{ // è la parte dell'error handling
                    setShowError('flex');
                    setHideElements('none')
                    setTimeout(() => {
                        setShowError('none');
                        setHideElements('flex')
                        refUsername.current.value = '';
                        refPassword.current.value = '';
                    }, 2000)
                }
            })
            .catch(error => console.log('error', error));
    };

    function goToPrivacyPolicy(e){
      e.preventDefault();
      history.push('/pages/policy');
    }

    return (
      <motion.div 
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      transition={{ ease: "easeOut", duration: 1 }}
      exit={{ opacity: 0 }}>
        <div className='loginWrapper' id='loginWrapper'>
          <h1 className='loginH1'>Presidenti di Quartiere</h1>
          <div className='fotoDavid' id='fotoDavid' style={{background: `url(${david}) no-repeat`, backgroundSize: 'contain', backgroundPosition: 'center'}}></div>
          <div className="loginContainer">
            <div className='LogosDiv'>
              <div className='logoFirenzeLogin'>
                  <img src={logoFirenze} alt='logoFirenze'/>
              </div>
              <div className='logoARegoladArteLogin'>
                  <img src={logoARegolaDArte} alt='logo_a_regola_d_arte'/>
              </div>
              <div className='logoAvrLogin'>
                  <img src={logoAvr} alt='logoAvr'/>
              </div>
            </div>
            <input type='text' onChange={(e) => setUsername(e.target.value)} placeholder='username' ref={refUsername} style={{display: hideElements}}/>
            <input type='password' onChange={(e) => setPassword(e.target.value)} placeholder='password' ref={refPassword} style={{display: hideElements}}/>
            <p style={{display: showError}} className='errorMsg'>Errore nell'inserimento dello username e/o password</p>
            <button onClick={() => goToDashboard()} className="button" style={{display: hideElements}}>Login</button>
          </div>
          <button className='linkPrivacyLogin' onClick={(e) => goToPrivacyPolicy(e)}>Privacy</button>
        </div>
      </motion.div>
    );
};