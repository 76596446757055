import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import marker from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet'
import '../styles/mappa.css'
import Right from '../right/right';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '../styles/dati.css'


export default function Mappa( props ){

    /** PROPS */
    
    const elem = props.elem;
    const showMap = props.showMap;
    const showSpinner = props.showSpinner;

    /** VARIABILI UTILITY */
    var getCoordinates;
    var lat;
    var long;
    var arrCoordinate = [];
    var center = [43.7731357203235, 11.255902001004932]

    /** Conditional rendering del component */
    //nel periodo di load delle info visualizza uno spinner, poi renderizza la mappa
    if(Object.keys(elem).length > 0){
        //se la response non contiene le coordinate faccio dirigere il marker al centro oppure renderizzo la mappa iniziale?
        if(elem.bodyfindSegnalazione[0].geom_segn == '' || elem.bodyfindSegnalazione[0].geom_segn == null){
            return <Right />
        }else { //altrimenti prende le coordinate dalla response e le usa per disegnare il marker

            //se sono più coordinate
            if(Object.values(elem.bodyfindSegnalazione[0].geom_segn).includes('|')){
                let objNumbers = elem.bodyfindSegnalazione[0].geom_segn.split('|')
                for(let i = 0; i < objNumbers.length; i++){

                    let coordinate = JSON.parse(objNumbers[i])

                    //devo switchare le coordinate nell'array
                    let helper = coordinate.coordinates[1];
                    coordinate.coordinates[1] = coordinate.coordinates[0];
                    coordinate.coordinates[0] = helper;
                    arrCoordinate.push(coordinate.coordinates)
                }
            } else { //se le coordinate sono di un singolo elemento
                getCoordinates = JSON.parse(elem.bodyfindSegnalazione[0].geom_segn);
        
                /* console.log('getCoordinates', getCoordinates) */
    
                long = getCoordinates.coordinates[0];
                lat = getCoordinates.coordinates[1];
            }
        }
    } else {
        return <div className="divLoading" style={{display: showMap}}> 
                <FontAwesomeIcon 
                    icon={faSpinner} 
                    size='3x'
                    style={{display: showSpinner}}
                    className='spinnerIcon'
                />
            </div>
    }

    /* console.log('array delle coordinate', arrCoordinate); */

    function FindLocationMarker(){
        const map = useMapEvent({
            click(){
                map.locate()
            },  
            locationfound(){
                map.flyTo([lat, long], 17)
            }
        })
        return lat === null || long === null ? 
            <Marker position={center}
                icon={new Icon({iconUrl: marker, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>Nessuna geolocalizzazione possibile</Popup>
            </Marker>
            :
            <Marker position={[lat, long]}
                icon={new Icon({iconUrl: marker, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].indirizzo + ' ' + elem.bodyfindSegnalazione[0].civico + ' ' + elem.bodyfindSegnalazione[0].quartiere : ''}</Popup>
            </Marker>
    };

    function MultipleMarkers(){
        const map = useMapEvent({
            click(){
                map.locate()
            },  
            locationfound(){
                map.flyTo(arrCoordinate[0], 17)
            }
        });
        return arrCoordinate.map((coordinata, i) => {
            return (
            <Marker position={coordinata} key={i}
                icon={new Icon({iconUrl: marker, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>{Object.keys(elem).length > 0 ? elem.bodyfindSegnalazione[0].indirizzo + ' ' + elem.bodyfindSegnalazione[0].civico + ' ' + elem.bodyfindSegnalazione[0].quartiere : ''}</Popup>
            </Marker>
            );
        })
    }
    
    return (
        <div id="mapid" style={{display: showMap, zIndex: 0}}>
        <MapContainer 
            center={center} zoom={12} 
            scrollWheelZoom={true}
            style={{ height: "100%", width: "100%" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                arrCoordinate.length > 0 ? <MultipleMarkers /> : <FindLocationMarker />
            }
            
        </MapContainer>
        </div>
    );
};