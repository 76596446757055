import React, { useEffect, useState } from "react";

import '../styles/header.css'

export default function Header( props ) {

    /** HOOKS */
    const [quartiere, setQuartiere] = useState('')
    const [totals, setTotals] = useState();
    const [ticketQuartiere, setTicketQuartiere] = useState()

    /** PROPS */
    const socket = props.socket;
    const setShowSpinnerApp = props.setShowSpinnerApp;
    const setShowApp = props.setShowApp;

    /** useEffect che prende dal localStorage il token. Commentare se vogliamo accedere senza login */
    useEffect(() => {
        const token = localStorage.getItem('token')
        setQuartiere(token.slice(1,3).toUpperCase())
    }, [])

    /** useEffect che prende i valori dei count */
    useEffect(() => {
        if(socket){
            socket.on('countAll', function(dataAll){
                if(dataAll){
                    setShowSpinnerApp('none')
                    setShowApp('flex')

                    //scommentare in local testing
                    /* setTotals(dataAll[0].count)
                    setTicketQuartiere(dataAll[1].count) */

                    //scommentare in production
                    setTotals(dataAll[0][0][0].count)
                    setTicketQuartiere(dataAll[1][0].count)
                } else {
                    setTotals(0)
                    setTicketQuartiere(0)
                }
            })
        }
        return () => {
            setTotals()
            setTicketQuartiere()
        }
    }, [socket])

return(
    <div className="headerContainer">
        <div className="headerTextContainer">
            <h3>Benvenuti!</h3>
            <p>sull'app di Ma.G.I.Stra pensata per i Presidenti di Quartiere</p>
        </div>
        <div className="headerTickets">
            <h5>Da Settembre 2020 abbiamo ricevuto</h5>
            {
                quartiere == 'QA' ?
                <p><span>{totals ? totals : 0}</span> ticket totali</p>
                :
                <p><span>{totals ? totals : 0}</span> ticket totali di cui <span>{ticketQuartiere ? ticketQuartiere : 0}</span> Ticket sul {quartiere}</p>
            }
        </div>
    </div>
);

};