import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';


import './App.css';
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import Policy from './components/pages/policy';

function App() {
  return (
    <Router>
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route exact path ="/" render={props => <Login {...props}/>}  />
          <Route exact path ="/dashboard" render={props => <Dashboard {...props}/>} />
          <Route exact path ="/pages/policy" render={props => <Policy {...props}/>} />
        </Switch>
      </AnimatePresence>
    </Router>
  );
}

export default App;
