import React from 'react'
import { useHistory } from 'react-router-dom'

import '../styles/policyContainer.css'

export default function Policy() {

    const history = useHistory();

    function GoBack(e){
        e.preventDefault()
        history.goBack();
    }

    return (
        <section className="policyContainer">
            <h1 className="title">Privacy Policy</h1>
            <h3 className="policyTitle">Informativa ai sensi dell’art. 13 del Regolamento (UE) 2016/679 
                (Regolamento Generale sulla Protezione dei Dati)
            </h3>
            <p className="policySubTitle">Ai sensi dell’art. 13 del Regolamento (UE) 2016/679, ed in relazione ai suoi dati personali di cui la nostra società entrerà in possesso, La informiamo di quanto segue:
            </p>
            <ol className="numberedList">
                <li className="numberedListTitle">1.	Titolare del trattamento</li>
                <p className="numberedListText">Titolare del trattamento è AVR S.p.A., con sede in Via Francesco Tensi, 116 - 00133 Roma, mail info@avrgroup.it</p>
                <li className="numberedListTitle">2.	Responsabile della protezione dei dati</li>
                <p className="numberedListText">Responsabile della protezione dei dati è l’Avv. William Di Cicco, con studio in Roma, Via Tibullo n. 10, tel. 06.68136714 / 06.68135583 - mail: <b>w.dicicco@legalevda.it</b>
                </p>
                <li className="numberedListTitle">3.	Finalità e base giuridica del trattamento dei dati</li>
                <p className="numberedListText">Il trattamento dei Suoi dati personali è finalizzato alla alla gestione e riscontro delle segnalazioni richieste mediante la compilazione dell’apposito form sull’APP Presidenti di quartiere, sulla base del consenso volontario espresso con l’invio della richiesta
                </p>
                <li className="numberedListTitle">4.	Conferimento necessario dei dati e conseguenze del rifiuto del consenso</li>
                <p className="numberedListText">Il trattamento dei Suoi dati personali è finalizzato alla alla gestione e riscontro delle segnalazioni richieste mediante la compilazione dell’apposito form sull’APP Presidenti di quartiere, sulla base del consenso volontario espresso con l’invio della richiesta
                </p>
                <li className="numberedListTitle">5.	Modalità di trattamento dei dati</li>
                <p className="numberedListText">Nel momento in cui viene inviato il form compilato mediante l’apposita sezione dell’App, il Titolare del trattamento acquisisce i dati personali inseriti. 
                I dati saranno trattati da personale autorizzato in modo lecito, corretto e trasparente e in maniera da garantire un’adeguata sicurezza mediante misure tecniche e organizzative adeguate. Verranno trattati unicamente i dati adeguati, pertinenti e necessari alla finalità indicata. Il trattamento potrà avvenire in forma cartacea e digitale con l’ausilio di strumenti informatici e i dati saranno conservati su banche dati del Titolare <u>e in cloud presso società terze con sede all’interno dell’Unione europea</u>.
                </p>
                <li className="numberedListTitle">6.	Diffusione e comunicazione dei dati ed eventuali destinatari</li>
                <p className="numberedListText">I Suoi dati non saranno oggetto di diffusione. Potranno, ove necessario, essere comunicati ad uffici e personale autorizzato di AVR S.p.A. e all’Ente gestore dell’App Presidenti di Quartiere.
                </p>
                <li className="numberedListTitle">7.	Periodo di conservazione dei dati</li>
                <p className="numberedListText">I dati saranno conservati per tutta la durata del servizio richiesto e per le esigenze contabili e contrattuali con l’Ente gestore dell’App Presidenti di Quartiere
                </p>
                <li className="numberedListTitle">8.	Diritti dell’interessato</li>
                <p className="numberedListText">La informiamo che potrà esercitare i Suoi diritti previsti dagli articoli da 15 a 22 del Regolamento (UE) 2016/679 nei confronti del Titolare del trattamento e richiedere in ogni momento la conferma che sia o meno in corso un trattamento di dati personali che la riguardano e le informazioni attinenti alle modalità di trattamento, le finalità, le categorie di dati personali, i destinatari a cui i dati personali sono stati o saranno comunicati, il periodo di conservazione, nonché ottenere una copia di quanto richiesto in un formato strutturato, di uso comune e leggibile da dispositivo automatico. 
                Ha diritto di richiedere al Titolare la rettifica o l’integrazione dei Suoi dati personali che ritiene inesatti o incompleti, anche fornendo una dichiarazione integrativa. 
                Potrà inoltre richiedere la cancellazione dei Suoi dati personali o la limitazione del relativo trattamento oppure opporsi in tutto o in parte per motivi legittimi al loro trattamento, oppure revocare il consenso al trattamento; l’esercizio di tali diritti potrebbe comportare l’impossibilità di proseguire o portare a termine la finalità indicata.
                Tutte le suddette richieste andranno inoltrate al Titolare del trattamento attraverso i contatti sopra indicati.  
                Se le suddette richieste saranno presentate mediante mezzi elettronici, le informazioni Le saranno fornite, ove possibile, con mezzi elettronici, salvo diversa Sua indicazione. 
                Le informazioni, comunicazioni e le azioni del Titolare in riscontro alla Sue richieste sono gratuite, ma in caso di richieste manifestamente infondate, eccessive o ripetitive, il Titolare potrà addebitare un contributo spese ragionevole tenendo conto dei costi amministrativi sostenuti, oppure rifiutare di soddisfare le richieste. 
                Qualora il Titolare del trattamento nutra ragionevoli dubbi circa l’identità della persona fisica che presenta le suddette richieste, potrà richiedere ulteriori informazioni necessarie per confermare l’identità dell’interessato.
                </p>
                <li className="numberedListTitle">9.	Reclamo</li>
                <p className="numberedListText">Qualora ritenga che i Suoi diritti previsti dalla normativa in materia di protezione dei dati personali siano stati violati, potrà proporre reclamo all’Autorità Garante per la protezione dei dati personali ai sensi dell’art. 77 del Regolamento (UE) 2016/679, secondo le modalità reperibili all’indirizzo <a target='_blank' href="https://www.garanteprivacy.it/">www.garanteprivacy.it</a> o adire le opportune sedi giudiziarie (art. 79 del Regolamento).</p>
            </ol>
            <button className='buttonEsciPolicy' onClick={(e) => GoBack(e)}>Esci</button>
        </section>
    )
}
