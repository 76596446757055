import React, { useState, useEffect, useRef } from 'react'

import '../styles/right.css';
import img1 from '../../mappaFirenze.svg'


export default function Right( props ){

  /** PROPS */
  const showRight = props.showRight;
  const socket = props.socket;

  /** HOOKS */
  const path1 = useRef(null);
  const path2 = useRef(null);
  const path3 = useRef(null);
  const path4 = useRef(null);
  const path5 = useRef(null);
  const [path1X, setPath1X] = useState(0);
  const [path1Y, setPath1Y] = useState(0);
  const [path2X, setPath2X] = useState(0);
  const [path2Y, setPath2Y] = useState(0);
  const [path3X, setPath3X] = useState(0);
  const [path3Y, setPath3Y] = useState(0);
  const [path4X, setPath4X] = useState(0);
  const [path4Y, setPath4Y] = useState(0);
  const [path5X, setPath5X] = useState(0);
  const [path5Y, setPath5Y] = useState(0);
  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);

  const quartieriObject = {
    'CAMPO DI MARTE': 0,
    'CENTRO STORICO': 0,
    'RIFREDI': 0,
    'GAVINANA GALLUZZO': 0,
    'ISOLOTTO LEGNAIA': 0
  }

  /** useEffect per le percentuali */
    useEffect(() => {
      if(socket){
        socket.on('countAll', function(dataPercentage){
          console.log(dataPercentage)
          if(dataPercentage){

              // build real array commentare in local
              const quartieriData = dataPercentage[4].map((val) => {
                if(val.quartiere in quartieriObject === true){
                  quartieriObject[val.quartiere] = val.count
                }
                return true
              })

              let quartieriTotale = 0;
              for(let q in quartieriObject){
                quartieriTotale += +quartieriObject[q]
              }
              console.log(quartieriTotale, quartieriObject)

              //scommentare in production
              setQ1(Math.round((quartieriObject['CENTRO STORICO'] / quartieriTotale) * 100)); //num ticket quartiere / num totali ticket x 100;
              setQ2(Math.round((quartieriObject['CAMPO DI MARTE'] / quartieriTotale) * 100));
              setQ3(Math.round((quartieriObject['GAVINANA GALLUZZO'] / quartieriTotale) * 100));
              setQ4(Math.round((quartieriObject['ISOLOTTO LEGNAIA'] / quartieriTotale) * 100));
              setQ5(Math.round((quartieriObject['RIFREDI'] / quartieriTotale) * 100));
              
              //scommentare in local testing
              /* setQ1(50)
              setQ2(20)
              setQ3(10)
              setQ4(20)
              setQ5(14) */
          } else {
              setQ1(0);
              setQ2(0);
              setQ3(0);
              setQ4(0);
              setQ5(0);
            }
          })
        }
      return () => {
          setQ1(0);
          setQ2(0);
          setQ3(0);
          setQ4(0);
          setQ5(0);
        }
      }, [socket])

    /** useEffect che setta le % al caricamento della pagina */
    useEffect(() => {
      setPath1X(path1.current.getBBox().x + 145);
      setPath1Y(path1.current.getBBox().y + 150);
      setPath2X(path2.current.getBBox().x + 190);
      setPath2Y(path2.current.getBBox().y + 120);
      setPath3X(path3.current.getBBox().x + 165);
      setPath3Y(path3.current.getBBox().y + 195);
      setPath4X(path4.current.getBBox().x + 70);
      setPath4Y(path4.current.getBBox().y + 140);
      setPath5X(path5.current.getBBox().x + 100);
      setPath5Y(path5.current.getBBox().y + 80);
      return () => {
        setPath1X(0);
        setPath1Y(0);
        setPath2X(0);
        setPath2Y(0);
        setPath3X(0);
        setPath3Y(0);
        setPath4X(0);
        setPath4Y(0);
        setPath5X(0);
        setPath5Y(0);
      }
    }, [])

    //useEffect che fa la 3d perspective della foto
    useEffect(() => {
      // Init
      var container = document.getElementById("rightContainer"),
          inner = document.getElementById("foto");
    
      // Mouse
      var mouse = {
        _x: 0,
        _y: 0,
        x: 0,
        y: 0,
        updatePosition: function(event) {
          var e = event || window.event;
          this.x = e.clientX - this._x;
          this.y = (e.clientY - this._y) * -1;
        },
        setOrigin: function(e) {
          this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
          this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
        },
        show: function() {
          return "(" + this.x + ", " + this.y + ")";
        }
      };
    
      // Track the mouse position relative to the center of the container.
      mouse.setOrigin(container);
    
      //----------------------------------------------------
    
      var counter = 0;
      var refreshRate = 10;
      var isTimeToUpdate = function() {
        return counter++ % refreshRate === 0;
      };
    
      //----------------------------------------------------
    
      var onMouseEnterHandler = function(event) {
        update(event);
      };
    
      var onMouseLeaveHandler = function() {
        inner.style.background = `${img1}`;
        inner.style.backgroundPosition = 'center';
      };
    
      var onMouseMoveHandler = function(event) {
        if (isTimeToUpdate()) {
          update(event);
        }
      };
    
      //----------------------------------------------------
    
      var update = function(event) {
        mouse.updatePosition(event);
        /* console.log(mouse.x + " " + mouse.y); */
        updateTransformStyle(
          (mouse.y / inner.offsetHeight / 2).toFixed(2),
          (mouse.x / inner.offsetWidth / 2).toFixed(2)
        );
      };
    
      var updateTransformStyle = function(x, y) {
        var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
        inner.style.transform = style;
        inner.style.webkitTransform = style;
        inner.style.mozTranform = style;
        inner.style.msTransform = style;
        inner.style.oTransform = style;
      };
    
      //--------------------------------------------------------
    
      document.onmousemove = onMouseMoveHandler;
      document.onmouseleave = onMouseLeaveHandler;
      document.onmouseenter = onMouseEnterHandler;
    }, [])

    /** useEffect che controlla la viewport dell'applicativo. Se è minore di 1024px esegue mobileGyroscope */
    useEffect(() => {
      if(window.innerWidth <= 1024){
        window.addEventListener('deviceorientation', mobileGyroscope, true);

        return () => {
            window.removeEventListener('deviceorientation', mobileGyroscope, true);
        }
      }
    }, [])

    /** Funzione per eseguire il giroscopio da mobile */
    const mobileGyroscope = (e) => {
      var container = document.getElementById('rightContainer');
      var foto = document.getElementById('firenze');

      var maxX = container.clientWidth  - foto.clientWidth;
      var maxY = container.clientHeight - foto.clientHeight;
      
      var initialGy = 90;
      var initialGx = 0;

      var gy = Math.floor(e.beta) + initialGy;  //front back motion
      var gx = Math.floor(e.gamma) + initialGx; //left to right

      /* console.log('gy', gy, 'gx', gx); */

      /* if (gx >  90) { gx =  90};
      if (gx < -90) { gx = -90}; */

      /* if (gy >  -90) { gy =  90};
      if (gy < 90) { gy = -90}; */

      // To make computation easier we shift the range of
      // x and y to [0,180]
      /* gx += 90;
      gy += 90; */

      var style = "rotateX(" + Math.floor(maxY*gy/foto.clientHeight) + "deg) rotateY(" + Math.floor(maxY*gx/foto.clientWidth) + "deg)";

      foto.style.transform = style;
      foto.style.webkitTransform = style;
      foto.style.mozTranform = style;
      foto.style.msTransform = style;
      foto.style.oTransform = style;
    };

    return (
        <div className="rightContainer" id='rightContainer' style={{display: showRight}}>
          <div className='fotoContainer' id='foto'>
            <svg inkscapeversion="0.92.1 r15371" sodipodidocname="Quartiere 5.svg" xmlSpace="preserve" xmlnscc="http://creativecommons.org/ns#" xmlnsdc="http://purl.org/dc/elements/1.1/" xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape" xmlnsosb="http://www.openswatchbook.org/uri/2009/osb" xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlnssvg="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="firenze" /* style="enable-background:new 0 0 288.6 237;" */ version="1.1" viewBox="0 0 288.6 237" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px" style={{fill: '#E2E2E2'}}>{/* <style type="text/css"> */} {/* .st0  */}{/* style={{fill: '#E2E2E2'}} */} {/* </style> */}<g inkscapegroupmode="layer" inkscapelabel="Livello 1" id="quartieri" transform="translate(274.79229,-261.31222)"><g id="g4611">
              <path ref={path1} inkscapeconnector-curvature="0" className="st0" d="M-122.4,447.1l0.4-3.2l-2.7-6.3c-3.4-7.9-4.6-8.8-12.6-8.8
              c-6.6-0.1-10.6-1.2-9.9-2.9c0.2-0.6,0-2-0.4-3.2c-0.6-1.6-0.5-3.1,0.3-5.9l1.1-3.7l1.5,0.2c0.8,0.1,2.2,0.6,3.1,1.1
              c3.5,1.8,7.9-2.9,5.4-5.8l-0.9-1.1l2-2.6c1.1-1.5,2-3.6,2-4.9v-2.3l-2.4-1c-1.3-0.6-4-1.7-5.8-2.6c-1.9-0.9-8.8-3.8-15.4-6.6
              c-6.6-2.8-14.5-6.3-17.6-7.8c-6.6-3.2-25.8-14.8-26.1-15.8c-0.4-1.1,6.2-1.7,13.9-1.3l7.3,0.4l10.3,3.5l10.3,3.5l1.2-1
              c0.6-0.5,2.2-2.4,3.4-4.2c2.5-3.6,3-3.8,5.8-1.8c1.1,0.8,6,3.5,10.8,6.1l8.8,4.7l4.6-0.5l4.6-0.5l2.1,2.5l2.1,2.5l1.7-1.1
              c1-0.6,3.2-1.8,5.1-2.7l3.4-1.6l2,1.4c1.1,0.8,3.4,3.4,5.1,5.8c1.7,2.4,3.8,4.6,4.6,4.8c1.2,0.3,1.4,0.8,1.1,2.1
              c-0.3,1.1,0.2,2.4,1.4,3.9c1,1.2,1.8,3,1.8,4c0,1,0.4,2.7,0.9,3.7l0.9,2l-1.4,2.6c-1.1,2-1.2,2.8-0.5,3.6c1.5,1.8,2.2,5.4,1.4,6.8
              c-0.6,1-0.3,1.7,0.9,3.1l1.7,1.8l-2.5,0.2c-2,0.2-2.6,0.6-2.9,2.1c-0.4,1.9-5.8,8.2-7.1,8.2c-0.4,0-1.5,0.7-2.4,1.6l-1.7,1.6
              l-2-0.9c-2.7-1.2-4.2-0.6-5.9,2.7c-1,1.8-2,2.7-2.8,2.6c-2.2-0.4-4.1,1.2-5.1,4.2c-0.5,1.6-1.4,3.4-2,4.1c-0.6,0.7-1,1.9-0.9,2.6
              c0.3,1.6-0.6,3.3-1.8,3.3C-122.5,450.3-122.6,449.1-122.4,447.1L-122.4,447.1z" id="q1">
              </path>
              <path ref={path2} inkscapeconnector-curvature="0" className="st0" d="M-31.2,413.4c-1.8-0.4-6.4-1.7-10.1-3c-8.4-2.9-13.9-3.1-22.9-0.7
              c-4.2,1.1-8.8,1.7-13.4,1.8l-7.1,0.1l-0.7-2.6c-0.4-1.4-1-2.9-1.3-3.3c-0.3-0.3-0.1-1.5,0.4-2.6c1.2-2.3,1.3-4.1,0.3-5.9
              c-0.4-0.7-0.9-2.5-1.1-4c-0.2-1.5-1.1-3.3-1.9-4c-0.8-0.7-1.3-1.6-1.1-2c0.7-1.2-0.5-3.6-2-4c-0.8-0.3-2.8-2.4-4.4-4.8
              c-1.6-2.4-3.8-4.9-4.9-5.6c-2.2-1.4-2.4-3.1-0.8-5.3c1.4-1.9,1.4-7-0.1-10.5l-1.2-2.8l0.8-6.2c0.4-3.4,1.3-7.5,1.9-9l1.2-2.8
              l-2.2-2.3c-2.7-2.8-3.4-4.9-2.5-7.3c0.4-1.1,0.4-3.4,0-6l-0.7-4.2l3.3-4.4c3.9-5.2,5.1-7.4,6.9-13c2.1-6.5,5.6-12.6,9-15.5
              l3.1-2.6v-6.3v-6.3l1.5-2.1c0.9-1.2,3.1-2.6,5.4-3.4l3.9-1.3l0.8,1c0.4,0.5,1.1,2.4,1.4,4.2c0.7,4,1.9,4.5,3.8,1.5l1.4-2.3
              l0.9,1.1c0.5,0.6,1.3,0.9,1.7,0.7c0.4-0.2,1.4,0.3,2.4,1.2c0.9,0.9,3.2,2.8,5.2,4.2l3.6,2.6v3.1c0,2.3-0.5,3.7-2.2,5.6
              c-1.2,1.4-2.2,3-2.2,3.5s-1,1.9-2.2,3c-1.7,1.5-2.2,2.7-2.2,4.6c0,1.6-0.5,3.1-1.4,3.9c-7.7,7-11,11-12.3,14.9
              c-0.7,1.9-1.5,3-2.5,3.1c-1.2,0.2-1.6,1-2,3.7c-0.3,1.9-0.5,4.7-0.5,6.1l0,2.7l5,2.5c4,2,6.2,3.8,10.7,8.9
              c3.1,3.5,5.9,6.9,6.3,7.5c0.4,0.6,1.8,5.7,3.2,11.3c2.6,10.5,3,11.3,6.4,13.8l2.1,1.5l5.4-1.9c7.5-2.6,11.2-6.3,13-13.1
              c1.3-4.9,4.2-9.2,5.2-7.7c0.3,0.4,1.2,0.8,2.2,0.8s2.1,0.4,2.5,0.8c1.2,1.2,7.3-5.5,8.9-9.7l1.2-3.2h2h2l2.3,4.3
              c1.3,2.4,3,4.7,3.9,5.3c0.9,0.6,1.5,1.7,1.5,2.8c0,1.3,1.1,3,3.6,5.2c2,1.8,3.8,3.3,4.1,3.4c1.3,0,0.3,2.3-2.4,5.4
              c-3.9,4.4-4.1,5.9-1,6.8l2.4,0.6l-0.5,2c-0.3,1.4-0.1,2.2,0.7,2.8c1.5,1.1,0.9,3.9-0.9,4.3c-0.8,0.2-1.5,1.4-1.8,3.1
              c-0.3,1.5-1.5,3.9-2.7,5.3c-1.3,1.4-2,3-1.8,3.6c0.3,0.7-0.5,1.7-2.2,2.8c-1.5,0.9-4.2,3.5-6.2,5.8c-4.2,4.9-8.8,7.4-15.3,8.5
              C-24,414.3-26.5,414.3-31.2,413.4L-31.2,413.4z" id="q2">
              </path>
              <path ref={path3} inkscapeconnector-curvature="0" sodipodinodetypes="ccccccsccccccsscccsscccccscccccccccccccccccssccssscccccsccsscccccscsccscccccccccscccscccscccccccccscc" className="st0" d="
              M-174.6,496.1c-0.2-1.2-0.6-3.2-0.9-4.4c-0.6-3-2.8-4.9-5.1-4.3l-1.9,0.5l0-2.9c0-1.8-0.8-4.4-2.3-7.2c-1.3-2.4-2.3-5.3-2.3-6.4
              c0-2.6,2.4-4.9,4.2-3.9c1.4,0.8,6.3-0.1,8.6-1.5c2.4-1.5,1.7-3.1-1.9-4.9c-2.5-1.2-3.7-2.4-4.8-4.8l-1.5-3.2l2.2-2.1
              c1.2-1.1,3-2.1,4-2.1c3.7,0,10.9-8.5,10.9-12.9c0-2.9,4.6-7.9,8.3-9l2.9-0.9l5.8,2.2c4.6,1.7,7.1,2.2,11.6,2.2c7,0,7.8,0.6,11,8.1
              l2.3,5.4l-0.4,3.5c-0.3,2.8-0.2,3.6,0.8,4c1.8,0.7,5.1-1.7,4.6-3.4c-0.4-1.2,0.3-3.3,2.9-8.3c0.7-1.4,1.5-1.9,3.1-1.9
              c1.7,0,2.5-0.6,3.8-2.8c2.2-3.8,2.2-3.8,5.2-2.8l2.7,1l1.8-1.6c1-0.9,2.1-1.7,2.5-1.7c1.1,0,7.4-6.8,7.9-8.5
              c0.3-1,1.1-1.7,2.5-1.8c2.8-0.3,3.4-2.3,1.3-4c-2.1-1.7-1.6-1.9,7.1-2.4c3.7-0.2,9.6-1.1,13.1-2c9.3-2.4,14.4-2.2,24.4,1.2
              c8.7,2.9,14.7,3.6,22.5,2.5c2.7-0.4,4.1-0.3,5.1,0.5l1.4,1l-1.1,2.7c-1.1,2.7-3.1,3.5-4.7,1.9c-0.6-0.6-1-0.6-1.3,0
              c-0.3,0.4-1.2,0.8-2,0.8c-1,0-1.5,0.5-1.5,1.6c0,1.2-0.3,1.5-1.2,1.1c-0.8-0.3-1.6,0.1-2.3,1.1c-0.8,1.1-1.3,1.4-1.9,0.8
              c-1.2-1.2-2.8-1-2.8,0.3c0,0.6-0.5,1.1-1.1,1.1c-1.3,0-2.6,7.7-1.3,8.1c0.5,0.2,0.6,1.2,0.4,2.3c-0.2,1.1,0,3.5,0.4,5.3
              c0.6,2.1,0.6,3.5,0.1,4.1c-0.4,0.5-1,2.1-1.3,3.7c-0.3,1.7-0.9,2.8-1.6,2.8c-1.1,0-1,0.8,0.3,4.4c0.2,0.6-0.3,1.2-1.2,1.4
              c-2.9,0.8-1.4,5.5,1.8,5.5c1.2,0,1.8,0.4,1.8,1.2c0,0.9-0.3,1-1.3,0.5c-0.7-0.4-2.4-0.5-3.7-0.3c-1.6,0.2-3.6-0.2-5.7-1.2
              c-3.4-1.7-9.1-2.1-11-0.8c-0.8,0.5-1.4,0.3-2.2-0.7c-1.5-1.9-11-6.8-13.2-6.8c-2.7,0-4.1,0.8-4.9,2.7c-0.5,1.2-1.3,1.8-2.6,1.8
              c-1.1,0-2.6,0.3-3.3,0.8c-0.9,0.5-1.6,0.5-1.9,0c-0.7-1.1-4.3-0.9-6.1,0.4c-0.9,0.6-2.4,2.4-3.3,3.9c-0.9,1.5-2.9,4.2-4.5,5.9
              s-2.9,3.5-3.2,4.1l-0.4,1.1l-0.7-1.1l-0.7-1.1l-1.2,1c-0.7,0.6-1.9,0.8-3,0.5c-1.4-0.4-2.1-0.1-2.7,1.1c-0.5,0.9-1.8,1.9-3,2.3
              c-1.2,0.4-2.4,1.4-2.6,2.1c-0.2,0.7-1.8,2.2-3.4,3.3l-3,2v3c0,3.5-2,5.2-6.5,5.5l-2.9,0.2l-1.3-2.1c-1-1.5-1.9-2.1-3.6-2.1
              c-1.3,0-3.2-0.7-4.3-1.5l-2-1.5l-8.2-0.1l-8.2-0.1l0.2,3.5l0.2,3.5l-1.3,0.1c-0.7,0.1-1.8,0.1-2.4,0c-0.6,0-2.9,0.4-5,1
              c-2.1,0.6-5.1,1.1-6.5,1.1h-2.6L-174.6,496.1z" id="q3">
              </path>
              <path ref={path4} inkscapeconnector-curvature="0" className="st0" d="M-183.5,447.1c-0.2-0.8-1-1.7-1.8-1.9c-4.8-1.6-5.8-2.2-6.8-4.8
              c-0.6-1.5-1.7-3.3-2.3-4.1l-1.2-1.4l1.3-0.9c0.7-0.5,1.1-1.2,0.8-1.4c-0.3-0.3,0.4-0.9,1.5-1.4c2.5-1.1,2.5-1.7,0.1-2.8
              c-1-0.5-1.9-1.4-1.9-2c0-0.6-1.4-1.8-3.2-2.7c-1.8-0.9-4.4-2.8-5.9-4.2l-2.7-2.6l-0.8,1.4c-0.9,1.6-0.8,1.7-3.4-2.9l-2-3.6
              l-1.8,1.2c-1,0.7-4.2,2.3-7.1,3.7l-5.3,2.5l-3-2.7l-3-2.7l-2.6,0.9c-3,1-5,0.3-6.8-2.5c-0.8-1.2-2.2-1.9-4.3-2.3
              c-4.3-0.7-7.7-2.4-10.5-5.4c-1.3-1.4-2.8-2.5-3.4-2.5c-1.6,0-6.4-2.7-8.3-4.7l-1.8-1.9l1.3-1.9c0.7-1.1,1.6-1.9,2-1.9
              c1.2,0-0.1-4-1.8-5.5l-1.6-1.4l1.6-5l1.6-5l10.1-2.9c5.5-1.6,14.7-3.5,20.4-4.3c5.7-0.8,14.2-2.1,18.9-3l8.5-1.6l3.3,1.3
              c1.8,0.7,8.5,4.4,14.9,8.2c6.4,3.8,15.6,8.7,20.5,10.9c4.9,2.2,10.2,4.6,11.9,5.3c1.6,0.7,4.7,2,6.8,2.8c7.7,3,16.9,7.6,16.9,8.4
              c0,0.5-0.9,2.4-2,4.2l-2,3.4l0.9,1.4c0.6,1,0.7,1.8,0.1,2.9c-0.9,1.8-1,1.8-5.2,0.3l-3.3-1.2l-0.9,1.3c-1.7,2.3-2.8,8.9-1.8,10.8
              c1.3,2.4,0.1,3.2-3.1,2.2l-2.8-0.8l-3.4,1.3c-4.6,1.7-8.7,6.4-8.7,9.8c0,4-5.6,10.4-10.4,12C-182,449-183,448.9-183.5,447.1
              L-183.5,447.1z" id="q4">
              </path>
              <path ref={path5} inkscapeconnector-curvature="0" className="st0" d="M-117.4,373.1l-1.6-2.3l-4.7,0.5l-4.7,0.5l-4.4-2.1
              c-2.4-1.2-7.5-3.9-11.2-6.1c-4.7-2.7-7.3-3.8-8.3-3.5c-0.8,0.3-2.7,2.1-4.1,4.1l-2.6,3.7l-5.9-2.3c-9.9-3.9-16.2-5.1-25-5.1
              c-7.7,0-12.7,0.9-12.7,2.3c0,0.4-1.3,0.1-2.8-0.5c-3.1-1.3-6.6-1.1-15.6,0.9c-2.9,0.6-10,1.8-15.9,2.6c-5.8,0.8-14.6,2.6-19.5,4.1
              c-4.9,1.4-11,2.8-13.6,3.1l-4.7,0.5v-1.2c0-0.7,0.6-1.5,1.4-2c0.8-0.5,2.1-2.9,3.1-5.8c0.9-2.8,2.1-5.6,2.6-6.2
              c1.9-2.3,3.3-12,1.8-12c-0.6,0-1.5-0.3-2.1-0.7l-1-0.7l1.7-2.8l1.7-2.8l4.2,0.5c9.8,1.1,28.4,3.4,31.3,3.9l3.1,0.5l2.3-4.6
              c1.3-2.5,2.6-4.6,2.9-4.6c0.3,0,1.4-1.4,2.4-3.2c4-7.2,9.3-15.6,9.9-15.6c1.2,0,6.6,3,8.1,4.5c0.8,0.8,2.9,2.2,4.6,3.1l3.2,1.6
              l1.2-1.7c0.6-0.9,2-3.1,3-4.8c5.7-9.8,10.7-17.6,12.6-19.6c2.2-2.5,9.7-6.7,13.1-7.4c2.3-0.5,2.8-0.9,7-6.4
              c1.6-2.2,3.5-4.6,4.2-5.5c1.5-2,3.3-1.3,5,1.8c0.7,1.3,2,3.2,2.9,4.3l1.6,1.9l2.7-1.7c2.5-1.6,3.1-2.4,2.8-4.1
              c-0.1-0.3,0.6-1.6,1.4-2.7c0.8-1.2,1.7-3.2,2-4.5c0.3-1.3,0.9-2.3,1.5-2.3c1.4,0,2.6-2.5,1.8-3.8c-1-1.6-0.9-2.1,1-2.6
              c0.9-0.3,2.4-1.2,3.3-2l1.7-1.5l3.2,0.5c1.8,0.3,4.6,0.7,6.4,0.9l3.1,0.4l5.5,5.5c3,3,6,5.5,6.6,5.5s1.6,0.7,2.1,1.5l0.9,1.5h5.1
              c2.8,0,5.3,0.2,5.6,0.5c0.3,0.3-1.1,2.2-3.1,4.3c-3.4,3.6-5.4,7.5-9.4,18.6c-1.2,3.2-2.3,4.9-7.2,11.3l-1.6,2.1l0.7,4.3
              c0.5,2.6,0.5,5,0,6.1c-0.9,2.3,0.3,6.1,2.7,8.3l1.8,1.7l-1.1,2.6c-0.6,1.4-1.4,5.3-1.9,8.6l-0.8,6l1.2,3.5
              c1.5,4.3,1.5,7.3,0.1,9.9c-0.6,1.1-1.2,2.5-1.4,3.2c-0.2,0.7-2.4,2.2-4.9,3.5l-4.6,2.3L-117.4,373.1L-117.4,373.1z" id="q5">
              </path></g></g>
              {
                path1.current ? 
                <text className='textMappa'
                  x={path1X} 
                  y={path1Y}>
                    {q1 ? q1 + '%' : null}
                </text>
                :
                ''
              }
              {
                path2.current ? 
                <text className='textMappa'
                  x={path2X} 
                  y={path2Y}>
                    {q2 ? q2 + '%' : null}
                </text>
                :
                ''
              }
              {
                path3.current ? 
                <text className='textMappa'
                  x={path3X} 
                  y={path3Y}>
                    {q3 ? q3 + '%' : null}
                </text>
                :
                ''
              }
              {
                path4.current ? 
                <text className='textMappa'
                  x={path4X} 
                  y={path4Y}>
                    {q4 ? q4 + '%' : null}
                </text>
                :
                ''
              }
              {
                path5.current ? 
                <text className='textMappa'
                  x={path5X} 
                  y={path5Y}>
                    {q5 ? q5 + '%' : null}
                </text>
                :
                ''
              }
            </svg>
          </div>
        </div>
    );
};