import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import '../styles/left.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Dati from '../dati/dati';

export default function Left( props ){

    //props
    const setSegnalazione = props.setSegnalazione;
    const segnalazione = props.segnalazione;
    const socket = props.socket;
    const setShowSpinner = props.setShowSpinner;
    const showSpinner = props.showSpinner;
    const setShowElem = props.setShowElem;
    const showElem = props.showElem;
    const elem = props.elem;
    const setElem = props.setElem;
    const setShowMap = props.setShowMap;
    const setShowRight = props.setShowRight;

    //hooks
    const [showSearch, setShowSearch] = useState('flex');
    const [showDati, setShowDati] = useState('none');
    const [appToken, setAppToken] = useState('');
    const history = useHistory();
    const ref = useRef();

    var objReq = {}
    let token;

    /** useEffect che controlla se nel localStorage è presente il token. Se non c'è torna al login */
    useEffect(() => {
        token = localStorage.getItem('token');
        setAppToken(token)
        /* console.log('Sono passato dalla dashboard', token); */
        if(!token){
            history.push('/')
        }
    }, [])

    /** Funzione che prende l'id di getico inserito dall'utente e lo spedisce nel channel */
    const InsertId = (e) => {
            e.preventDefault();
            /* console.log('segnalazione', segnalazione) */
            if(segnalazione === undefined){ //se non viene inserita alcuna segnalazione non fare nulla
                return
            }
            setShowSpinner('flex');
            setShowElem('none');
            setShowSearch('none');
            setShowDati('flex');
            setShowMap('flex');
            setShowRight('none');

            objReq = {
                id: segnalazione,
                status: 'added',
                response: null,
                token: appToken,
                socket: socket.id
            }
            /* console.log(objReq) */ //quello che spedisco
            ref.current.value = '';
            //invio dell'oggetto dopo l'input
            socket.emit('idAggiunti', objReq)
    }


    return ( 
        <>
        {/* <h3 className='mobileH3'>Benvenuti</h3> */}
        <div className="leftContainer">
            <div className='leftWrapper' style={{display: showSearch}}>
                <div className='textLeft'>
                    <h3>Gestione Getico</h3>
                    <p>Digitate il numero di Getico per visualizzare l'andamento della segnalazione</p>
                </div>
                <div className='inputDiv'>
                    <input 
                        ref={ref}
                        className="inputText" 
                        type="text" 
                        placeholder="Inserisci num. ticket"
                        onChange={(e) => setSegnalazione(e.target.value)}
                    />
                    <FontAwesomeIcon icon={faSearch} className='searchIcon'/>
                </div>
                <div className='leftButton'>
                    <button onClick={(e) => InsertId(e)} className='button'>Cerca</button>
                </div>
            </div>
            <div className="datiContainer" style={{display: showDati}}>
                <Dati 
                    socket={socket}
                    segnalazione={segnalazione}
                    setSegnalazione={setSegnalazione}
                    showSpinner={showSpinner}
                    setShowSpinner={setShowSpinner}
                    showElem={showElem}
                    setShowElem={setShowElem}
                    setShowSearch={setShowSearch}
                    setShowDati={setShowDati}
                    elem={elem}
                    setElem={setElem}
                    setShowMap={setShowMap}
                    setShowRight={setShowRight}
                />
            </div>
        </div>
        </>
    );
};